import gql from 'graphql-tag'

export const TableUpdateFragment = gql`
  fragment TableUpdate on Table {
    title
    roles
    allowsNoFilter
    footer
    exportable
    filterByDefault
    defaultLimit
    unlimited
    orderByAsc
    orderByDefault
    cssClassField
    fixedHeaders
    disableRefresh
    sortable
    fields {
      fieldName
      label
      type
      options
    }
  }
`

export const TableCreateFragment = gql`
  fragment TableCreate on Table {
    _id
    environmentId
    collectionId
    createdAt
  }
`

export const TableFragment = gql`
  fragment Table on Table {
    ...TableCreate
    collection {
      fields {
        name
        label
        type
        options
      }
    }
    ...TableUpdate
  }

  ${TableCreateFragment}
  ${TableUpdateFragment}
`

export const TableEditFragment = gql`
  fragment TableEdit on Table {
    ...TableUpdate
    name
    filtersIds
    orderFiltersByName
    exportWithId
  }

  ${TableUpdateFragment}
`
