


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import { TableFragment } from './fragments'
import { Table, ViewItem } from '@/models'
import ViewItems from './ViewItems.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import gql from 'graphql-tag'
import { GraphQLError } from 'graphql'
import xlsx from 'xlsx'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  components: {
    Loading,
    ComponentHeader,
    FilterForm,
    ViewItems
  },
  apollo: {
    table: {
      query: gql`
        query getTable($tableId: ID) {
          table(tableId: $tableId) {
            ...Table
            filters {
              _id
              title
              schema: serializedSchema(includeParameters: true)
              formSchema: serializedSchema(includeParameters: false)
            }
          }
        }
        ${TableFragment}
      `,
      variables() {
        return {
          tableId: this.componentId
        }
      }
    }
  }
})
export default class TableView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem

  table: Table | null = null
  filter: FilterValue | null = null

  @Watch('table')
  setDefaultFilter(table: Table) {
    if (table.filterByDefault) {
      this.filter = {
        filterId: table.filterByDefault,
        filterOptions: this.viewParams
      }
    } else if (table.allowsNoFilter) {
      this.filter = { filterId: undefined, filterOptions: this.viewParams }
    } else if (table.filters && table.filters.length >= 1) {
      this.filter = {
        filterId: table.filters[0]._id,
        filterOptions: this.viewParams
      }
    }
  }

  exporting = false
  async exportTable() {
    if (!this.table || this.exporting) return
    try {
      this.exporting = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation exportTable(
            $tableId: ID
            $filterId: ID
            $filterOptions: JSON
            $params: JSON
          ) {
            result: exportTable(
              tableId: $tableId
              filterId: $filterId
              filterOptions: $filterOptions
              params: $params
            )
          }
        `,
        variables: {
          tableId: this.table._id,
          filterId: this.filter && this.filter.filterId,
          filterOptions: this.filter && this.filter.filterOptions,
          params: this.viewParams
        }
      })
      if (data.result) {
        xlsx.writeFile(
          data.result,
          `${this.table.title || this.table.name || this.$t('tables_view.script.exportTable.document')}.xlsx`
        )
      } else {
        throw new Error(this.$t('tables_view.script.exportTable.error').toString())
      }
    } catch (e) {
      let message = e.message
      if (e.graphQLErrors)
        message = e.graphQLErrors.map((e: GraphQLError) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + message,
        color: 'error',
        timeout: 10000
      })
      console.error(e)
    } finally {
      this.exporting = false
    }
  }
}
